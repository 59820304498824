<script setup lang="ts">
function handleClick() {
  // make a error
  const a = {} as any;
  console.log('handleClick', a.b.c);
}
</script>

<template>
  <div>
    <button @click="handleClick">123123123</button>
  </div>
</template>
<style lang="scss" scoped></style>
